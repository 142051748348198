.App {
  background: linear-gradient(#E3A726, #eeaa44);
  text-align: center;
}


.App-header {
  /* background-color: #eeaa44; */
   display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #E9EBF8;
}

.App-link {
  color: #EEAA44;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.App-Div {
  background:linear-gradient(#eeaa44, #895E10, #71520F); 
  height: 100vh;
  display: flex;
  flex-Direction: column;
  justify-Content: center;
  align-items: center;
  

}
